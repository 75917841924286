* {
  box-sizing: border-box;
}
html,
body {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
h1 {
  font-weight: 400;
}
